.wrap-page{
  display: flex;
  flex-direction: column;
}
.wrap-content-page{
  flex: 1;
  align-items: stretch;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.head-title{
  padding: 20px 0;
}
div[class*="Carousel-prev-"] {
  position: relative;
  order: -1;
  display: block;
}

div[class*="Carousel-next-"] {
  position: relative;
  order: 1;
  display: block;
}

.CarouselButtons {
  background-color: #E4F1F9 !important;
  padding: 0 !important;
}

.CarouselButtons svg {
  color: #006CB1;
  font-size: 8vh;
}

.CarouselButtonsWrapper button:disabled {
  filter:grayscale(1) !important
}


.CarouselButtonsWrapper:hover button:disabled {
  opacity: 1 !important;
}

.CarouselButtonsWrapper:hover button:not([disabled]) {
  filter: contrast(0.8) !important;
  opacity: 1 !important;
}

.MuiPickersModal-dialogRoot *{
  font-size: 20px!important;
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root button{
  height: auto;
}
.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar *{
  color: #fff!important;
}
.MuiPickersModal-dialogRoot .MuiPickersDay-daySelected{
  color: #fff!important;
}

.caroussel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.carouselWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.BannerGrid {
  justify-content: center;
}

/*
  Theme: myTheme1
*/
.simple-keyboard.myTheme1 {
  background-color: #E4F1F9;
  border-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  color: #006CB1;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 40px;
  margin: 15px;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}

::placeholder {
  color: #B2CEDF;
}
